var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('GoBackBtn',{attrs:{"routeToGo":_vm.routeToGo,"tabId":false}}),_c('PageHeader',{attrs:{"title":_vm.title}}),_c('v-card',[_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.saveConfig()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"5"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],attrs:{"label":"Días de plazo desde el último backup","dense":"","clearable":"","outlined":"","rules":_vm.cantDiasBackup !== 0
                  ? _vm.rules.required.concat(
                      _vm.rules.maxLength(_vm.cantDiasBackup, 2),
                      _vm.rules.minLength(_vm.cantDiasBackup, 1)
                    )
                  : []},model:{value:(_vm.cantDiasBackup),callback:function ($$v) {_vm.cantDiasBackup=$$v},expression:"cantDiasBackup"}})],1),_c('v-col',{staticClass:"pb-0 mt-1",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"right":"","max-width":"40%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.infoIcon)+" ")])]}}])},[_c('span',[_vm._v(" Cantidad de días transcurridos desde la última copia de seguridad realizada a partir del cual se mostrará una alerta informando sobre esta situación al momento de iniciar sesión en el sitio. En caso de ser 0 no hará el control. ")])])],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid || _vm.cantDiasBackup === '',"form":"form","color":"primary"}},[_vm._v(" Actualizar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }